<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="70">
            </el-table-column>
            <el-table-column
                prop="levelId"
                label="分类">
            </el-table-column>
            <el-table-column
                prop="name"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="headName"
                label="负责人名称">
            </el-table-column>
            <el-table-column
                prop="headPhone"
                label="负责人电话">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态">
            </el-table-column>
            <el-table-column
                prop="updateUserId"
                label="修改人">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button @click.native="tableEdit(scope.row)" type="text">编辑</el-button>
                    <el-button @click.native="tableDel(scope.row)" type="text">删除</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='80%' :before-close='cancel'>
        <el-form ref='form' :model='form' :rules='rules' label-width='130px'>
          <el-form-item label='分类' prop='levelId'>
            <el-select v-model="form.parentId" placeholder="请选择分类" @change="handleChange">
              <el-option
                v-for="item in goodsLevelOneList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="form.levelId" placeholder="请选择分类">
              <el-option
                v-for="item in goodsLevelTwoList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='名称' prop='name'>
              <el-input v-model='form.name' placeholder='请输入名称' />
          </el-form-item>
          <el-form-item label='内容'>
            <el-card class="box-card" v-for="(item, index) in contentList" :key="index">
              <div>
                <el-select v-model="item.audioModel" placeholder="请选择音频类型">
                  <el-option
                    v-for="item in audioModelSelect"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
                <el-input v-model='item.content' placeholder='请输入内容' type="textarea"/>
                <el-input v-model='item.url' placeholder='请输入图片地址'/>
                <img :src="item.url" width="100px">
                <el-upload
                  v-if="!item.url"
                  class="upload-demo1"
                  :action="upload.url"
                  :headers="upload.headers"
                  :before-remove="beforeRemove"
                  :on-success="handleSuccess"
                  :multiple="false"
                  :file-list="upload.fileList">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                <el-button style="float: right;" @click="handleContentDel(item.sort)">删除</el-button>
              </div>
            </el-card>
            <el-card class="box-card">
              <div>
                <el-select v-model="contentForm.audioModel" placeholder="请选择音频类型">
                  <el-option
                    v-for="item in audioModelSelect"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
                <el-input v-model='contentForm.content' placeholder='请输入内容' type="textarea"/>
                <el-input v-model='contentForm.url' placeholder='请输入图片地址'/>
                <img :src="contentForm.url" width="100px">
                <el-upload
                  v-if="!contentForm.url"
                  class="upload-demo2"
                  :action="upload.url"
                  :headers="upload.headers"
                  :before-remove="beforeRemove"
                  :on-success="handleSuccess"
                  :multiple="false"
                  :file-list="upload.fileList">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                <el-button style="float: right;" @click="handleContentAdd">添加</el-button>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item label='负责人名称' prop='headName'>
              <el-input v-model='form.headName' placeholder='请输入负责人名称' />
          </el-form-item>
          <el-form-item label='负责人电话' prop='headPhone'>
              <el-input v-model='form.headPhone' placeholder='请输入负责人电话' />
          </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>保 存</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { getGoodsPage, saveOrUpdateGoods, deleteGoods, getGoodsLevelAll } from '@/service/goods';
import store from '@/store';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        levelId: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ]
      },
      contentForm: {},
      contentList: [],
      upload: {
        url: process.env.VUE_APP_APIHOST + '/file_info/upload',
        headers: {
          Authentication: store.getters.token
        },
        fileList: []
      },
      goodsLevelOneList: [],
      goodsLevelTwoList: [],
      audioModelSelect: [
        {
          key: 'aifei',
          value: '艾飞(激昂解说)'
        },
        {
          key: 'zhimiao_emo',
          value: '知妙_多情感'
        },
        {
          key: 'zhibei_emo',
          value: '知贝_多情感'
        }
      ]
    };
  },
  created() {
    this.getList();
    this.getGoodsLevel();
  },
  methods: {
    getList() {
      getGoodsPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    getGoodsLevel(id){
      getGoodsLevelAll({id}).then(response => {
        if(id){
          this.goodsLevelTwoList = response['data'];
        } else {
          this.goodsLevelOneList = response['data'];
        }
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {};
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
        let contentList = JSON.parse(row.content);
        contentList = contentList.map((item) => {
          item.url = item.url[0];
          return item;
        });
        this.contentList = contentList;
        this.getGoodsLevel(row['parentId']);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
      this.contentList = [];
      this.contentForm = {};
      this.upload.fileList = [];
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if(!this.contentList || this.contentList.length === 0){
            this.$message({
                type: 'info',
                message: '内容不能为空'
            });
            return;
          }
          let contentList = this.contentList;
          contentList = contentList.map((item) => {
            item.url = [item.url];
            return item;
          });
          const content = JSON.stringify(contentList);
          this.form = {
            ...this.form,
            content
          };
          if (this.form.id !== undefined) {
            saveOrUpdateGoods(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.getList();
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          } else {
            saveOrUpdateGoods(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.getList();
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          }
        }
      });
    },
    tableDel(row){
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              deleteGoods({id: row.id}).then(response => {
                if (response.code === 0) {
                  this.$message({
                      type: 'success',
                      message: '删除成功!'
                  });
                  this.getList();
                } else {
                  this.$message({
                      type: 'info',
                      message: '异常'
                  });  
                }
              });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '已取消删除'
              });          
          });
    },
    handleContentAdd(){
      let contentForm = this.contentForm;
      if(!contentForm.content || !contentForm.url){
        this.$message({
            type: 'info',
            message: '必填项不能为空'
        });
        return;
      }
      let contentList = this.contentList;
      contentForm.sort = contentList.length + 1;
      contentList.push(contentForm);
      this.contentForm = {};
      this.upload.fileList = [];
    },
    handleContentDel(sort){
      let contentList = this.contentList;
      this.contentList = contentList.filter(item => item.sort !== sort);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess(res){
      if(res.code === 0){
        this.contentForm = {
          ...this.contentForm,
          url: res.data
        };
      } else {
        this.$message({
            type: 'info',
            message: '上传失败'
        });
      }
    },
    handleChange(id){
      this.getGoodsLevel(id);
    }
  }
};
</script>